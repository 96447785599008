import { render, staticRenderFns } from "./Help.vue?vue&type=template&id=bc133f3c&scoped=true&"
var script = {}
import style0 from "./Help.vue?vue&type=style&index=0&id=bc133f3c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc133f3c",
  null
  
)

export default component.exports